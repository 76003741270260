import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css'

export default function Card () {
    return (
        <div class="row">
  <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img
      src="https://i.ibb.co/41pMzWJ/1.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />

    <img
      src="https://i.ibb.co/3cxZT2q/2.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
    <img
      src="https://i.ibb.co/vYPkPNm/3.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
    <img
      src="https://i.ibb.co/4p41S9Z/4.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
    <img
      src="https://i.ibb.co/PMyYybP/5.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
    <img
      src="https://i.ibb.co/LvVWjPn/6.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="https://i.ibb.co/dLjyHRm/8.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />

    <img
      src="https://i.ibb.co/b3cpvMG/9.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />
    <img
      src="https://i.ibb.co/bBSY6tC/10.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />
    <img
      src="https://i.ibb.co/CJgLgMK/11.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />
    <img
      src="https://i.ibb.co/5nzSHKQ/12.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />
    <img
      src="https://i.ibb.co/TgTQLjw/13.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="https://i.ibb.co/KWkW665/15.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />

    <img
      src="https://i.ibb.co/J55yds6/16.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Yosemite National Park"
    />
    <img
      src="https://i.ibb.co/DKhFzsd/17.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />
    <img
      src="https://i.ibb.co/g7RjTpp/18.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />
    <img
      src="https://i.ibb.co/25rHrhC/19.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />
    <img
      src="https://i.ibb.co/jGzKxTH/14.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />
    <img
      src="https://i.ibb.co/4Rs9njv/7.jpg"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
  </div>
</div>
    );
};