import React from 'react';
import './Cards.css';
import Card from './Card.jsx'
import { Link } from 'react-router-dom';

export default function Cards() {
  return (
<div class="blog-card spring-fever">
    <div class="title-content">
    <h3>2022</h3>
    <hr />
    <div class="intro">Todas nuestras fotos</div>
    <br />
    <Link to='/fotos'>
    <button className='bttn' onClick={Card}>Click Aquí</button>
    </Link>
    </div>
    <div class="card-info">
      ❤❤ Unas fotos para ti ❤❤
    </div>
    <div class="utility-info">
    <ul class="utility-list">
      <li class="date">14.08.2022</li>
    </ul>
    </div>
    <div class="gradient-overlay"></div>
    <div class="color-overlay"></div>
</div>
  );
}
