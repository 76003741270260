import React from "react";
import './About.css'

function About() {
    return (
        <div>
            <h2 class='h2'>❤❤ALGUNOS CUMPLIDOS PARA TI❤❤</h2>
            <br />
            <br />
            <br />
            <h3>.- Eres mi primer y ultimo pensamiento del dia</h3>
            <br />
            <h3>.- MI felicidad no tiene precio, Pero si tu nombre</h3>
            <br />
            <h3>.- Gracias por darme tu amor, La mejor novia del mundo</h3>
            <br />
            <h3>.- Si te doy un cumplido por cada estrella, me faltaran para decirte lo bonita que sos</h3>
            <br />
            <h3>.- Gracias por soportar mis locuras</h3>
            <br />
            <h3>.- Me gusta tu perfume hoy, hueles al amor de mi vida</h3>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2 class='h2'>BONUS:</h2>
            <br />
            <br />
            <h3>Aver para cuando te dejan irte a vivir sola... bueno sola no, conmigo jajaj</h3>
            <h3> 👫 💑 💏 💏 💑 👫</h3>
            <br />
            <br />
            <br />
        </div>
    )
}

export default About